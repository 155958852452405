/* eslint-disable */
import React from 'react'
import "../components/layout.css"
import '../components/Header/Header.css'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import animationData from '../images/11264-swipe-up-arrows.json'
import ApplyUrlDiscount from '../components/ApplyUrlDiscount/ApplyUrlDiscount'
import Benefits from '../components/Benefits/Benefits'
import CartContext from '../components/Context/CartContext';
import ComparisonGrid from '../components/ComparisonGrid/ComparisonGrid'
import DesktopMenu from '../components/Header/DesktopMenu';
import Flavors from '../components/Flavors/Flavors'
import Footer from '../components/Footer'
import Formulas from '../components/Formulas/Formulas'
import Guarantee from '../components/Guarantee/Guarantee'
import Ingredients from '../components/Ingredients/Ingredients'
import Lottie from 'react-lottie'
import MediaQuery from 'react-responsive'
import MobileLogoCart from '../components/Header/MobileLogoCart';
import MobileMenu from '../components/Header/MobileMenu';
import PriceRow from '../components/PriceRow/PriceRow'
import Seo from '../components/SEO'
import { motion} from "framer-motion";
import { navigate } from "gatsby"
import { track } from '../../tracking'
import {StaticImage} from 'gatsby-plugin-image'
import {useContext} from 'react'
import {useEffect, useRef} from 'react'
const headerImg =  '../images/websiteimage.png'






//gradients
// pink - 'linear-gradient(45deg, #fad0c4 0%, #ffd1ff 100%)' 
// blue - 'linear-gradient(45deg, #fff1eb 0%, #ace0f9 100%)'
// green - 'linear-gradient(60deg, #abecd6 0%, #fbed96 100%)'

const StoreIndex = ({location}) => {
  //const { Mixpanel } = useStore()
  const {cartCount} = useContext(CartContext)
  const ref = useRef(null)

  useEffect(() =>{
    
    //could track different home page variants here
 
    track('Landing Page View')

    

    //console.log('u3',Mixpanel)
    //Mixpanel?.track('Landing Page View-test-v2')
    
  },[])
  




  async function handleCTA(location,ctaText){
    track('Landing Page CTA Click', {'Location':location, 'CTA Text':ctaText})
    navigate('/electrolyte-powder-drink-that-prevents-hangovers')
  }

  function handleScrollDownClick(){
    ref.current?.scrollIntoView({behavior: 'smooth'});
  }
 

  const backgroundVariantsV2 = {
    offscreen:{
      
    },
    onscreen:{
      
      backgroundImage: 'linear-gradient(to bottom, #fff1eb 0%, #ace0f9 100%)',
      transition: {
        duration: .4,
        ease: 'easeInOut',
      }
    }
  }

  const fadeInVariant={
    offscreen: {
      opacity: 0,
      
    },

    onscreen:{
      opacity: 1,
      y:0,
      transition: {
        duration: 1.5,
        ease: 'easeInOut',
      }
    }
  }
  
  const navDropIn = {
    offscreen: {
      opacity: 1,
      y:-20,
    },
    onscreen:{
      opacity: 1,
      y:0,
      transition: {
        duration: .75,
        ease: 'easeInOut',
      }
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
   
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


  return (
    <>
      
      <Seo
        title='Bender - Prevent hangovers with our Pre-Game electrolyte drink'
        description={`Bender's pre-game electrolyte formula is the perfect way to prep for a night of drinking. Just add it to your favorite beverage and enjoy. `}

      />
      <motion.div 
        
        className = 'home-page-container'
      >

        <motion.div 
          className = 'color-div'
          style={{height:'100vh'}}
          animate = {{
            backgroundImage:['linear-gradient(45deg, #fad0c4 0%, #ffd1ff 100%)','linear-gradient(45deg, #fff1eb 0%, #ace0f9 100%)','linear-gradient(60deg, #abecd6 0%, #fbed96 100%)'],
          }}
          transition={{
            duration: 5,
            ease: "easeInOut",
            yoyo: Infinity,
        
          }}
        >
          <motion.div 
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
            variants = {fadeInVariant} 
            className= 'home-page-header-wrap'
          >
            <div className = 'header-container'>
            <MediaQuery maxWidth= {600}>
              <MobileMenu
                location={location}
          
                cartCount={cartCount}
                
              />
              <MobileLogoCart />
            </MediaQuery>
            <MediaQuery minWidth= {600}>
          
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants = {navDropIn} 
              >
                <DesktopMenu
                  location={location}
                  cartCount={cartCount}

                />
              </motion.div>

            </MediaQuery>
            </div>
       

            <div className="home-page-header mobile-header-v2" style ={{marginTop:'0px'}} >
             
              <div className="header-text">
                <h1 className="title">
                  Beat your hangover before you start drinking

                </h1>
                <div className="subtitle" > 
                Bender's pre-game electrolyte formula is the perfect way to prep for a night of drinking. Just add it to your favorite beverage and enjoy. 
                </div>

               
                  <div className="buy-button" onClick = {()=> handleCTA('hero','SHOP NOW')}>SHOP NOW</div>
               
              </div>
              <div 
               
                className="header-image-div"
              >
                
                <StaticImage 
                  alt = 'Bender header image'
                  className="header-img"
                 
                  src={headerImg}
                  style={{width: '100%', height: 'auto'}}
                />
              </div>
            </div>
            
            <div className = 'lottie-header-div' onClick  ={handleScrollDownClick} id = 'lhd'>
                <Lottie options={defaultOptions}  style= {{transform:'rotate(180deg)'}} height={75} width={75} />
            </div>
           
            
          </motion.div>
        </motion.div>
        <div 
          style ={{height:'100vh', backgroundImage:'linear-gradient(to top, #fff1eb 0%, #ace0f9 100%)'}}
        >
          <Benefits variant ={'before drinking'} />
        </div>

        <motion.div  
          style = {{height:'100vh', backgroundImage:'linear-gradient(45deg, #fad0c4 0%, #ffd1ff 100%)'}}
        >
          <Formulas handleCTA = {handleCTA}/>
        </motion.div>

        <div >
          <Ingredients />
        </div>
        <motion.div 
          style = {{height:'100vh', backgroundImage:'linear-gradient(to bottom, #fff1eb 0%, #ace0f9 100%)'}}
        >
          <PriceRow handleCTA = {handleCTA} />
        </motion.div>

        <motion.div 
       
          
          style ={{paddingTop:'10%',paddingBottom:'15%',backgroundImage: 'linear-gradient(45deg, #fad0c4 0%, #ffd1ff 100%)'}} >
          <ComparisonGrid variant = {'before drinking'}/>
        </motion.div>


        <div  >
        <Flavors handleCTA = {handleCTA} />
        </div>
        <motion.div initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }} variants = {backgroundVariantsV2}  
        >
          <Guarantee handleCTA = {handleCTA}/>
        </motion.div>
        {/*}
        <motion.div className="section-scroll"
        initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }} variants = {backgroundVariantsV3} >

        <Testimonials version={'landing-page'} />
        
        </motion.div>
        */}
        <div  style = {{height:'auto', backgroundImage:'linear-gradient(60deg, #abecd6 0%, #fbed96 100%)'}}>
          <Footer />
        </div>
      </motion.div>
      <ApplyUrlDiscount location={location} />
    </>
  )
}

export default StoreIndex